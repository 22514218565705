.capacities-status {
    .capacity-def {
        &.full {
            color: orangered;
        }
        &.almostfull {
            color: orange;
        }
    }
}

// bootstrap table row hover highlight color
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    color: white;
    background-color: rgba(45, 45, 45, 0.72);
}

.table-hover tbody tr.is-selected {
    background-color: rgba(0, 128, 0, 0.45);

    td:first-child {
        border-left: 10px solid rgba(0, 128, 0, 1);
    }
}

// this override needed because the bootstrap hover highlight color affects this too
.bootstrap-datetimepicker-widget {
    td,
    th {
        background-color: white !important;
    }
}

.ember-right-click-menu {
    &[data-show] {
        z-index: 10000;
    }

    border: 0;
    padding: 0.25rem !important;
    background: rgba(0, 0, 0, 0.72);

    .ember-right-click-menu__item {
        &--disabled {
            opacity: 0.5;
        }

        &:hover {
            background: rgba(108, 117, 125, 1);
        }

        + .ember-right-click-menu__item {
            margin-top: 0.25rem !important;
        }

        display: block;
        width: 100%;
        padding: 1px 6px;
        border: 0;
        border-radius: 0.2rem;

        background: rgba(108, 117, 125, 0.65);

        font-size: 0.875rem;
        line-height: 1.5;
        color: #ffffff;
        text-align: left;

        transition: all 0.1s ease-out;
    }
}

.smaller-button {
    padding: 3px 6px;
    font-size: 80%;
    line-height: 1.5;
}

.x-select-theme {
    .es-input {
        input:first-child {
            width: 100%;
        }
    }
    .es-option {
        color: #000 !important;
    }

    .header-col-message {
        width: 50%;
    }
}

.vehicle-selector {
    display: flex;
    .accept-buttons {
        display: inline-flex;
    }
}
