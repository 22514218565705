.side-panel-container {
    padding: 0;
    // position: absolute;
    // top: 1em;
    // right: 1em;
    display: flex;
    flex-flow: column;
    width: 35%;
    min-width: 30em;
    // border: 1px solid red;

    .secondary-text {
        font-size: 0.85em;
    }
}

.side-panel {
    z-index: 1;
    pointer-events: auto;
    max-height: 100%;
    padding: 1em;
    border-radius: 0.5em;
    margin-bottom: 0.75em;
    background-color: rgba(darken($panel-background, 20%), $detail-panel-transparency);
    font-size: $detail-panel-font-size;

    &:last-child {
        margin-bottom: 0;
    }

    .card-title {
        color: white;
        text-decoration: none;
    }

    .table-div {
        height: 30em;
        overflow-y: auto;
    }

    .small-table-div {
        height: 15em;
        overflow-y: auto;
    }

    .growing-table-div {
        // a table wrapper with the minimum height defined
        min-height: 15em;
    }

    tr.is-late {
        background-color: rgba(red, 0.25);
    }

    tr.is-driving {
        background-color: rgba(green, 0.45);
    }

    tr.is-absent {
        background-color: rgba(red, 0.25);
    }

    .medium-wide-col {
        width: 100px;
    }

    .tabbed-div {
        display: flex;
        flex-flow: column;
        overflow: hidden;
        max-height: 100%;

        .nav-tabs {
            // extend bootstrap styles
            flex-shrink: 0;
            border-bottom: 0;

            span {
                margin-right: 10px;
                cursor: pointer;
            }

            .active {
                // extend bootstrap styles
                border-bottom: 2px solid #dee2e6;
            }
        }

        .tab-content {
            // extend bootstrap styles
            overflow: auto;
        }

        .tab-pane.vinka-flex-column.active {
            display: flex;
        }
    }

    .toggle-button {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: row-resize;
        .toggle-collapsed {
            display: none;
        }
        .toggle-expanded {
            display: block;
        }

        &.collapsed {
            .toggle-expanded {
                display: none;
            }
            .toggle-collapsed {
                display: block;
            }
        }
    }

    .filter-tiny {
        display: inline-block;
        max-width: 40px;
        padding: 0;
    }
    .filter-big {
        display: inline-block;
        max-width: 100px;
        padding: 0;
    }
}

.applied-filters {
    .applied-filter {
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #ffffff;
        border: 0;
        border-radius: 0.2rem;
        background: rgba(108, 117, 125, 0.65);
        transition: all 0.1s ease-out;

        &:hover {
            background: rgba(108, 117, 125, 1);
        }
    }

    &.drop-filters {
        padding: 0.25rem !important;
        background: rgba(0, 0, 0, 0.72);
        max-height: 60%;
        overflow: auto;
        .applied-filter {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}
